import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { TrainerPostModel } from "../Types/TrainerPostModel";
import { getCastedValue } from "../Utilty";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { Form } from "./Form/Form";
import { FormTextInput } from "./Form/FormTextInput";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { FormImageSelector } from "./Form/FormImageSelector";
import { FormAreaSelector } from "./Form/FormAreaSelector";
import { FormGenreSelector } from "./Form/FormGenreSelector";
import { Validator } from "../Types/Validator";
import { FormCheckbox } from "./Form/FormCheckbox";
import { FormMultipleImageSelector } from "./Form/FormMultipleImageSelector";
import { maxios } from "../MaxiosProvider";
import { FormRange } from "./Form/FormRange";
import { toTrainerPostModel, TrainerResponseModel } from "../Types/TrainerResponseModel";
import { TrainerType } from "../Types/TrainerType";
import { FormTrainerTypeRadioButton } from "./Form/FormTrainerTypeRadioButton";
import { LineUrl } from "../Resources/Strings";

type Props = {
	mode: "new" | "edit";
};

// フォームモデル⇒ポストモデルの誤差を埋めるために一部プロパティの型を上書きします。
// https://zenn.dev/fuqda/articles/4aba53fbb3d95b
interface TrainerFormModel extends Omit<TrainerPostModel, "trainerType"> {
	trainerType: TrainerType | undefined;
}

const TrainerForm: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const maxNumberOfGalleryImage = 10;
	const [trainer, setTrainer] = useState<TrainerFormModel>({
		trainerType: undefined,
		name: "",
		activityAreaIds: [],
		activityGenreIds: [],
		selfIntroduction: "",
		career: "",
		license: "",
		service: "",
		online: false,
		onsite: false,
		feePerOnceLowerLimit: undefined,
		feePerOnceUpperLimit: undefined,
		feePerMonthLowerLimit: undefined,
		feePerMonthUpperLimit: undefined,
		trialFeeLowerLimit: undefined,
		trialFeeUpperLimit: undefined,
		station: "",
		facebook: "",
		twitter: "",
		instagram: "",
		website: "",
		youTube: "",
		line: "",
		image: undefined,
		gallery: [],
		agreeToScoutCommunication: false,
	});
	const [gallery, setGallery] = useState<(string | undefined)[]>([
		...Array(maxNumberOfGalleryImage),
	]);
	const [loaded, setLoaded] = useState(false);
	const ref = useRef<Validator>(null);

	useEffect(() => {
		if (props.mode === "new") {
			setLoaded(true);
		} else if (props.mode === "edit") {
			maxios.get<TrainerResponseModel>(`/Trainers/me`).then((res) => {
				if (res.data) {
					setTrainer(toTrainerPostModel(res.data));

					setGallery(
						res.data.gallery.concat([...Array(maxNumberOfGalleryImage - res.data.gallery.length)])
					); // 要素数を10個に合わせる
				} else {
					navigate("/notfound");
				}

				setLoaded(true);
			});
		}
	}, [navigate, props.mode]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTrainer({ ...trainer, [event.target.name]: getCastedValue(event) });
	};

	const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setTrainer({ ...trainer, [event.target.name]: checked });
	};

	const handleChangeActivityAreas = (areaIds: string[]) => {
		setTrainer({ ...trainer, activityAreaIds: areaIds });
	};

	const handleChangeActivityGenres = (genreIds: string[]) => {
		setTrainer({ ...trainer, activityGenreIds: genreIds });
	};

	const handleChangeFeePerOnce = (
		lowerValue: number | undefined,
		upperValue: number | undefined
	) => {
		setTrainer({ ...trainer, feePerOnceLowerLimit: lowerValue, feePerOnceUpperLimit: upperValue });
	};

	const handleChangeFeePerMonth = (
		lowerValue: number | undefined,
		upperValue: number | undefined
	) => {
		setTrainer({
			...trainer,
			feePerMonthLowerLimit: lowerValue,
			feePerMonthUpperLimit: upperValue,
		});
	};

	const handleChangeTrialFee = (lowerValue: number | undefined, upperValue: number | undefined) => {
		setTrainer({ ...trainer, trialFeeLowerLimit: lowerValue, trialFeeUpperLimit: upperValue });
	};

	const updateImage = (base64: string) => {
		setTrainer({ ...trainer, image: base64 });
	};

	const updateGallery = (index: number, base64: string) => {
		setGallery(gallery.map((image, currentIndex) => (currentIndex === index ? base64 : image)));
	};

	const removeFromGallery = (index: number) => {
		// ギャラリーの配列は常に10個である必要があるため要素を削除してはいけません。
		setGallery(gallery.map((image, currentIndex) => (currentIndex === index ? undefined : image)));
	};

	const createTrainer = () => {
		return maxios.post("/Trainers", {
			...trainer,
			gallery: gallery.filter((x) => x),
		});
	};

	const updateTrainer = () => {
		return maxios.put("/Trainers", {
			...trainer,
			gallery: gallery.filter((x) => x),
		});
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		switch (props.mode) {
			case "new":
				return createTrainer().then(() => {
					return { isError: false, message: "登録しました。" };
				});
			case "edit":
				return updateTrainer().then(() => {
					return { isError: false, message: "登録しました。" };
				});
			default:
				throw new Error("");
		}
	};

	const navigateToMe = () => {
		authContext.refresh()!.then((context) => {
			navigate(`/trainers/${context!.matchoId}`);
		});
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTrainer({ ...trainer, agreeToScoutCommunication: event.target.checked });
	};

	return (
		<>
			{loaded && (
				<Box sx={{ textAlign: "center" }}>
					<Form style={{ width: "100%" }} ref={ref}>
						<FormImageSelector onSelected={updateImage} src={trainer.image} />
						<FormTrainerTypeRadioButton
							name={"trainerType"}
							value={trainer.trainerType}
							onChange={handleChange}
							required
						/>
						<FormTextInput
							label="ニックネーム"
							name="name"
							value={trainer.name}
							onChange={handleChange}
							sx={{ maxWidth: "20rem" }}
							minLength={1}
							maxLength={50}
							required
						/>
						<FormTextInput
							label="自己紹介"
							name="selfIntroduction"
							value={trainer.selfIntroduction}
							onChange={handleChange}
							multiline
							maxLength={2000}
						/>
						<FormAreaSelector
							areaIds={trainer.activityAreaIds}
							onChange={handleChangeActivityAreas}
							maxAreas={20}
						/>
						<FormGenreSelector
							genreIds={trainer.activityGenreIds}
							onChange={handleChangeActivityGenres}
						/>
						<FormTextInput
							label="経歴"
							name="career"
							value={trainer.career}
							onChange={handleChange}
							multiline
							maxLength={2000}
						/>
						<FormTextInput
							label="資格"
							name="license"
							value={trainer.license}
							onChange={handleChange}
							multiline
							maxLength={2000}
						/>
						<FormTextInput
							label="サービス"
							name="service"
							value={trainer.service}
							onChange={handleChange}
							multiline
							maxLength={2000}
						/>
						<FormTextInput
							label="最寄り駅"
							name="station"
							value={trainer.station}
							onChange={handleChange}
							maxLength={30}
						/>
						<FormCheckbox
							label="オンライン"
							name="online"
							checked={trainer.online}
							onChange={handleChangeCheckbox}
						/>
						<FormCheckbox
							label="出張"
							name="onsite"
							checked={trainer.onsite}
							onChange={handleChangeCheckbox}
						/>
						<FormRange
							label="一回当たりの料金"
							lowerValue={trainer.feePerOnceLowerLimit}
							upperValue={trainer.feePerOnceUpperLimit}
							onChange={handleChangeFeePerOnce}
							minValue={0}
							maxValue={999999}
						/>
						<FormRange
							label="月額料金"
							lowerValue={trainer.feePerMonthLowerLimit}
							upperValue={trainer.feePerMonthUpperLimit}
							onChange={handleChangeFeePerMonth}
							minValue={0}
							maxValue={999999}
						/>
						<FormRange
							label="体験料金"
							lowerValue={trainer.trialFeeLowerLimit}
							upperValue={trainer.trialFeeUpperLimit}
							onChange={handleChangeTrialFee}
							minValue={0}
							maxValue={999999}
						/>
						<FormTextInput
							label="Webサイト"
							name="website"
							value={trainer.website}
							onChange={handleChange}
							placeholder="http://matcho.com/"
							maxLength={128}
							regExp={/^https?:\/\/[-_.!~*()a-zA-Z0-9;/?@&=+$,%#]+$/}
						/>
						<FormTextInput
							label="Facebook"
							name="facebook"
							value={trainer.facebook}
							onChange={handleChange}
							prefix="https://www.facebook.com/"
							suffix="/"
							minLength={5}
							maxLength={50}
							// 英数字（a～z、0～9）とピリオドだけ
							regExp={/^[-a-zA-Z0-9.]+$/}
						/>
						<FormTextInput
							label="Twitter"
							name="twitter"
							value={trainer.twitter}
							onChange={handleChange}
							prefix="https://twitter.com/"
							suffix="/"
							minLength={5}
							maxLength={15}
							sx={{ maxWidth: "12rem" }}
							// 文字、数字、アンダースコアのみ
							regExp={/^[a-zA-Z0-9_]+$/}
						/>
						<FormTextInput
							label="Instagram"
							name="instagram"
							value={trainer.instagram}
							onChange={handleChange}
							prefix="https://www.instagram.com/"
							suffix="/"
							maxLength={30}
							sx={{ maxWidth: "20rem" }}
							// 半角英数字、ピリオド、アンダーバー
							regExp={/^[a-zA-Z0-9._]+$/}
							explain={`※入力するとInstagramの投稿があなたのページに表示されます。(プロアカウント(ビジネスアカウントまたはクリエイターアカウント)の場合のみ。個人アカウントでは表示されません。)表示したくない場合は入力しないでください。`}
						/>
						<FormTextInput
							label="YouTube"
							name="youTube"
							value={trainer.youTube}
							onChange={handleChange}
							prefix="https://www.youtube.com/channel/"
							maxLength={128}
							regExp={/^[-_a-zA-Z0-9]+$/}
							explain={`※入力するとYouTubeの投稿があなたのページに表示されます。表示したくない場合は入力しないでください。`}
						/>
						<FormTextInput
							label="LINE"
							name="line"
							value={trainer.line}
							onChange={handleChange}
							prefix={LineUrl}
							suffix="/"
							maxLength={30}
							sx={{ maxWidth: "20rem" }}
							regExp={/^[-_.@a-zA-Z0-9]+$/}
						/>
						<FormMultipleImageSelector
							onSelected={updateGallery}
							onRemove={removeFromGallery}
							src={gallery}
						/>
					</Form>
					<Box sx={{ textAlign: "left" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={trainer.agreeToScoutCommunication}
									onChange={handleCheckboxChange}
									name="agreeToScoutCommunication"
									color="primary"
								/>
							}
							label="ジムからのスカウトを受け取ります"
						/>
					</Box>
					<LongTimeProcessingButton
						caption="登録"
						onClick={register}
						callback={navigateToMe}
						variant="contained"
						sx={{ mt: 1 }}
					/>
				</Box>
			)}
		</>
	);
};

export default TrainerForm;
