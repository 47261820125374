import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { GymType } from "../../Types/GymType";
import { MatchoType } from "../../Types/MatchoType";
import { TrainerType } from "../../Types/TrainerType";
import { CardImage } from "./CardImage";

type Props = {
	type?: MatchoType;
	matchoSubType?: TrainerType | GymType;
	image?: string;
	name?: string;
	size?: "large" | "medium" | "small" | "xs";
	sx?: SxProps<Theme>;
};

export const Card: React.FC<Props> = (props) => {
	const getSizeNumber = (size?: "large" | "medium" | "small" | "xs") => {
		switch (size) {
			case "large":
				return 108;
			case "medium":
				return 96;
			case "small":
				return 64;
			case "xs":
				return 36;
			default:
				break;
		}
	};

	return (
		<Box sx={{ ...props.sx, display: "flex" }}>
			<CardImage
				matchoType={props.type}
				matchoSubType={props.matchoSubType}
				image={props.image}
				size={getSizeNumber(props.size)}
				alt={props.name}
			/>
			<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
				<Typography variant={props.size === "large" ? "h1" : undefined} sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
					{props.name}
				</Typography>
				{props.children}
			</Box>
		</Box>
	);
};

Card.defaultProps = {
	size: "medium",
};
